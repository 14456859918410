import React, { Component } from "react";


class About extends Component {
  render() {
    if (this.props.sharedBasicInfo) {
      var profilepic = "images/" + this.props.sharedBasicInfo.image;
      var url = this.props.sharedBasicInfo.url;
    }
    if (this.props.resumeBasicInfo) {
      var sectionName = this.props.resumeBasicInfo.section_name.about;
      var hello = this.props.resumeBasicInfo.description_header;
      var about = this.props.resumeBasicInfo.description;
    }

  

    return (
      <section id="about">
        <div className="col-md-12">
          <h1 style={{ color: "black" }}>
            <span>{sectionName}</span>
          </h1>
          <div className="row center mx-auto mb-5">
            <div className="col-md-4 mb-5 center">
              <div className="polaroid">
                
                <span style={{ cursor: "auto" }}>
                  <img
                    height="400px"
                    src={profilepic}
                    alt="Avatar placeholder"
                  />
                  <h2> 
                    {"View My Resume"}
                  </h2>
                <a
                  href={url}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                > 
                  <span
                      className="iconify"
                      data-icon="pixelarticons:download" data-width="30" data-height="30"
                      data-inline="false"
                    ></span>
                </a>  
                        
               </span>
              </div>
            </div>

            <div className="col-md-8 center">
              <div className="col-md-10">
                <div className="card">
                  <div className="card-header">
                    <span
                      className="iconify"
                      data-icon="pixelarticons:human-handsup" data-width="40" data-height="40"
                      data-inline="false"
                    ></span>{" "}
                    &nbsp;{" "}
                  </div>
                  <div
                    className="card-body font-trebuchet text-justify ml-3 mr-3"
                    style={{
                      height: "auto",
                      fontSize: "132%",
                      lineHeight: "200%",
                    }}
                  >
                    <br />
                    <span className="wave">{hello}  </span>
                    <br />
                    <br />
                    {about}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
